import { render, staticRenderFns } from "./DownloadResults.vue?vue&type=template&id=dffcb43e&"
import script from "./DownloadResults.vue?vue&type=script&lang=js&"
export * from "./DownloadResults.vue?vue&type=script&lang=js&"
import style0 from "~/assets/scss/sections/download/download-result-form.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports