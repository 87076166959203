<template>
  <el-popover
    :disabled="enableDownload"
    placement="top-start"
    width="200"
    trigger="hover"
    popper-class="download-disabled">
    <div class="popover-content">
      <div class="popover-tooltip-notification small-content">
        <h3>Export Feature</h3>
        <div class="flex-container">
          <i class="download-limit-icon large-icon"/>
          <p>Your Export Queue has reached its limit.
          Please wait for one of your reports to complete or
            <nuxt-link
              class="btn-default btn-text"
              to="/download-history">view</nuxt-link>
            Download history.
          </p>
        </div>
      </div>
    </div>
    <el-popover
      slot="reference"
      :disabled="!enableDownload"
      v-model="downloadVisible"
      placement="bottom-end"
      popper-class="light download-results-form with-footer">
      <div class="popover-content">
        <div class="pop-over-default">
          <fieldset>
            <h3>Format</h3>
            <el-radio-group v-model="fileType">
              <el-radio-button label="xlsx">XLSX</el-radio-button>
              <el-radio-button label="csv">CSV</el-radio-button>
            </el-radio-group>
          </fieldset>
          <fieldset>
            <h3>Filename</h3>
            <input
              v-model="fileName"
              type="text"
              placeholder="File Name (Optional)">
          </fieldset>
          <fieldset :class="{ disabled: selectedRows.length > 0 }">
            <h3>Range</h3>
            <div class="flex-container">
              <el-tooltip
                :value="errorAt === 'start' && downloadVisible"
                :manual="true"
                placement="bottom"
                popper-class="is-light">
                <template slot="content">
                  <div class="popover-tooltip-notification small-content">
                    <h3>{{ errorTitle }}</h3>
                    <div class="flex-container">
                      <i class="error-icon" />
                      <p>{{ errorMessage }}</p>
                    </div>
                  </div>
                </template>
                <input
                  v-model="startFrom"
                  :class="{ error: errorAt === 'start' }"
                  type="number"
                  min="0"
                  @keyup="validateDownload"
                  @keypress="validateNumberOnly">
              </el-tooltip>
              <el-tooltip
                :value="errorAt === 'end' && downloadVisible"
                :manual="true"
                placement="bottom"
                popper-class="is-light">
                <template slot="content">
                  <div class="popover-tooltip-notification small-content">
                    <h3>{{ errorTitle }}</h3>
                    <div class="flex-container">
                      <i class="error-icon" />
                      <p v-html="errorMessage"/>
                    </div>
                  </div>
                </template>
                <input
                  v-model="upTo"
                  :class="{ error: errorAt === 'end' }"
                  type="number"
                  min="0"
                  @keyup="validateDownload"
                  @keypress="validateNumberOnly">
              </el-tooltip>
              <a
                class="btn btn-text"
                @click="setAll">All</a>
            </div>
          </fieldset>
          <fieldset>
            <el-checkbox v-model="notify">
              <span class="el-checkbox__label">Notify me by email when done</span>
            </el-checkbox>
          </fieldset>
        </div>
      </div>
      <div class="popover-footer flex-container justify-right">
        <button
          class="btn btn-text secondary"
          @click="downloadVisible = false">Cancel</button>
        <button
          :class="{ disabled: downloadCredits === null }"
          class="btn btn-default btn-primary flex-items"
          @click="generate">{{ downloadCredits === null ? 'Generating...' : 'Generate File' }}
        </button>
      </div>
      <button 
        slot="reference"
        :class="{ disabled: !enableDownload || disabled }"
        class="btn btn-plain btn-sm">
        <span class="material-icons">download</span>
        Download Results
      </button>
    </el-popover>
  </el-popover>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  // @ts-ignore. Replace this if necessary.
  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons'
        }
      ]
    }
  },
  name: 'DownloadResults',
  mixins: [openUpgradeModal],
  props: {
    type: {
      type: String,
      required: true
    },
    resultLimit: {
      type: Number,
      required: true
    },
    // used for bookmarks
    item: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downloadVisible: false,
      fileName: '',
      startFrom: 1,
      upTo: 10,
      fileType: 'xlsx',
      notify: false,
      errorAt: '',
      errorMessage: '',
      errorTitle: '',
      IsDownloading: false,
      selected_rows: ''
    }
  },
  computed: {
    ...mapState({
      username: state => state.auth.user.username,
      email: state => state.auth.user.email,
      country: state => state.country
    }),
    ...mapState('downloads', ['enableDownload', 'selectedRows']),
    ...mapState('views', ['activeAggregateView']),
    ...mapGetters('userCredits', ['downloadCredits'])
  },
  watch: {
    resultLimit(newVal) {
      if (newVal) {
        this.upTo = newVal < 1000 ? newVal : 1000
      }
    },
    activeAggregateView(newView) {
      if (this.selectedRows[newView] && this.selectedRows[newView].length > 0) {
        this.startFrom = Math.min.apply(null, this.selectedRows[newView])
        this.upTo = Math.max.apply(null, this.selectedRows[newView])
      } else {
        this.startFrom = 1
        this.upTo = this.checkLimit(1000)
      }
    },
    selectedRows: {
      handler(newSelectedRows) {
        this.resetError()

        if (newSelectedRows[this.activeAggregateView].length > 0) {
          this.startFrom = Math.min.apply(
            null,
            newSelectedRows[this.activeAggregateView]
          )
          this.upTo = Math.max.apply(
            null,
            newSelectedRows[this.activeAggregateView]
          )
        } else {
          this.startFrom = 1
          this.upTo = this.checkLimit(1000)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.upTo = this.checkLimit(1000)
    document.addEventListener('keydown', e => this.readKeyPress(e))
  },
  destroyed() {
    document.removeEventListener('keydown', e => this.readKeyPress(e))
  },
  methods: {
    ...mapMutations('userCredits', ['setDownloadCredits']),
    ...mapMutations('downloads', ['setSelectedRows']),
    ...mapActions('userCredits', ['getUserCredits']),
    ...mapActions('downloads', ['generateDownload']),
    resetError() {
      this.errorTitle = ''
      this.errorMessage = ''
      this.errorAt = ''
    },
    validateDownload() {
      this.resetError()

      if (
        (+this.upTo - +this.startFrom > this.downloadCredits &&
          !this.selectedRows.length) ||
        this.selectedRows.length > this.downloadCredits
      ) {
        this.errorTitle = 'Download Results Limit Reached'
        this.errorMessage = `This report request exceeds the total number
        of download credits. Your account has
        ${
          this.downloadCredits > 0 ? this.downloadCredits : 0
        } remaining credits. <a href="#" class="upgrade-download inline-normal">Upgrade</a> your account to get more credits per month.`
        this.errorAt = 'end'

        if (!this.IsDownloading) {
          this.$nextTick(() => {
            document
              .getElementsByClassName('upgrade-download')[0]
              .addEventListener('click', this.showUpgrade)
          })
        }
      }

      if (+this.upTo > 100000) {
        this.errorTitle = 'Error'
        this.errorMessage = 'Last row cannot be greater than 100,000.'
        this.errorAt = 'end'
      }

      if (+this.startFrom > +this.upTo) {
        this.errorTitle = 'Error'
        this.errorMessage = 'Start value cannot be bigger than end value.'
        this.errorAt = 'start'
      }

      if (!['consignee', 'shipper', 'bookmarks'].includes(this.type)) {
        this.selectedRows[this.activeAggregateView].forEach(row => {
          if (+row < +this.startFrom) {
            this.errorTitle = 'Error'
            this.errorMessage = 'Start row cannot be greater than selected row'
            this.errorAt = 'start'
          }

          if (+row > +this.upTo) {
            this.errorTitle = 'Error'
            this.errorMessage = 'Last row cannot be less than selected row'
            this.errorAt = 'end'
          }
        })
      }

      if (this.selectedRows.length) {
        if (+this.upTo > +this.resultLimit) {
          this.errorTitle = 'Error'
          this.errorMessage =
            'Last row cannot be greater than the total number of results.'
          this.errorAt = 'end'
        }

        if (+this.startFrom > +this.upTo) {
          this.errorTitle = 'Error'
          this.errorMessage = 'Start row must be less than the last row.'
          this.errorAt = 'start'
        }

        if (+this.startFrom < 1) {
          this.errorTitle = 'Error'
          this.errorMessage = 'Start row cannot be less than 1.'
          this.errorAt = 'start'
        }
      }
    },
    generate() {
      if (this.IsDownloading) {
        return
      }
      this.IsDownloading = true
      this.resetError()

      this.validateDownload()

      if (this.errorAt && this.errorMessage) {
        setTimeout(() => {
          this.resetError()
        }, 4000)
        this.IsDownloading = false
        return
      }

      let payload = {
        fileName: this.fileName.length
          ? this.fileName
          : `${this.username}-${new Date().getTime()}`,
        fileType: this.fileType,
        email: this.email,
        notifyEmail: this.notify,
        startRow: this.selectedRows.length ? 1 : +this.startFrom,
        endRow: this.selectedRows.length
          ? Math.max.apply(null, this.selectedRows)
          : +this.upTo
      }

      if (this.type === 'bookmarks') {
        payload = {
          view: this.item.view,
          ...this.item.criteria,
          ...payload
        }
      }

      this.generateDownload({
        type: this.type,
        payload
      })

      this.getUserCredits()

      this.$bus.$emit('downloadStarted')
      this.$bus.$emit('unselect-rows')

      // Reset values to default
      this.downloadVisible = false
      this.fileName = ''
      this.startFrom = 1
      this.upTo = this.checkLimit(1000)
      this.fileType = 'xlsx'
      this.notify = false
      this.setSelectedRows({ view: this.activeAggregateView, rows: [] })
      // Delay availability of download button to prevent abuse/mistakes
      setTimeout(() => (this.IsDownloading = false), 2000)
    },
    setAll() {
      this.startFrom = 1
      this.upTo = this.checkLimit(100000)
    },
    validateNumberOnly(event) {
      if (!/^[0-9\s]+$/i.test(event.key)) {
        event.preventDefault()
      }
    },
    readKeyPress(e) {
      if (this.downloadVisible && e.key === 'Enter') {
        e.preventDefault()
        this.generate()
        return
      }
    },
    checkLimit(maxValue) {
      return this.resultLimit < maxValue ? this.resultLimit : maxValue
    },
    showUpgrade() {
      document
        .getElementsByClassName('upgrade-download')[0]
        .removeEventListener('click', this.showUpgrade)
      this.openUpgradeModal()
      this.resetError()
      this.downloadVisible = false
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/sections/download/download-result-form.scss"/>
