<template>
  <data-viewer
    :loading="loading"
    :data="paginatedData"
    :headers="modifiedHeaders"
    :sort-field="sortField"
    :sort-order="sortOrder"
    :total="total"
    :current-page="currentPage"
    :page-limit="pageLimit"
    :disabled-column-resizer="[0]"
    class="legacy-data-viewer"
    @sort="handleSortChange"
    @limit="handleLimitChange"
    @page="$emit('page', $event)">
    <template slot="headerRight">
      <download-results
        :result-limit="total"
        :type="companyType" />
    </template>
  </data-viewer>
</template>
<script>
import DataViewer from './DataViewer/DataViewer'
import DownloadResults from '~/components/data-viewers/DataViewer/DataViewerHeader/DownloadResults'
import { mapState, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'CompaniesDataViewer',
  components: { DataViewer, DownloadResults },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    companies: {
      type: Array,
      required: true
    },
    companyType: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('company', ['sortField', 'sortOrder', 'pageLimit']),
    ...mapGetters('company', ['templateFields']),
    modifiedHeaders() {
      return this.templateFields.map(field => {
        if (field.id === 'name') {
          switch (this.companyType) {
            case 'consignee':
              field.text = 'importer'
              break
            case 'shipper':
              field.text = 'supplier'
              break
            default:
              field.text = 'company'
          }
        }
        return field
      })
    },
    sortedCompanies() {
      const { companies } = this
      if (companies.length === 0) {
        return []
      }

      const sortFn =
        typeof companies[0][this.sortField] === 'string'
          ? company => company[this.sortField].toLowerCase()
          : company => company[this.sortField]

      return _.orderBy(companies, [sortFn], this.sortOrder)
    },
    data() {
      return this.companies.map((company, index) => {
        company.index = index + 1

        const data = this.templateFields.map(({ id }) => {
          let text = company[id]
          let link = ''
          let tooltip = ''
          let className = ''

          switch (id) {
            case 'index':
              link = `/company/${company.id}/overview`
              className = 'no-min-width'
              break
            case 'name':
              link = `/company/${company.id}/overview`
              className = `p-${id}`
              break
            case 'lastAddress':
              tooltip = text.fullAddress
              text = text.shortAddress || 'CITY/STATE UNAVAILABLE '
              break
            case 'calculatedTeu':
              text = text || 'n/a'
              break
          }

          const data = { text }
          if (link) data.link = link
          if (tooltip) data.tooltip = tooltip
          if (className) data.class = className
          return data
        })
        return { data }
      })
    },
    paginatedData() {
      const { start, end } = this.findIndexes(this.currentPage, this.pageLimit)
      return this.data.slice(start, end)
    }
  },
  methods: {
    findIndexes(currentPage, pageLimit) {
      const start = (currentPage - 1) * pageLimit
      const end = start + pageLimit
      return { start, end }
    },
    handleSortChange(payload) {
      this.$store.commit('company/setSorting', payload)
      this.$emit('page', 1)

      if (this.companyType === 'shipper') {
        this.$store.dispatch('company/suppliers/fetchCompanySuppliers')
      } else {
        this.$store.dispatch('company/importers/fetchCompanyConsignees')
      }
    },
    handleLimitChange(newPageLimit) {
      const { start: currentStartIndex } = this.findIndexes(
        this.currentPage,
        this.pageLimit
      )
      let newPage = 1

      let { start } = this.findIndexes(newPage, newPageLimit)
      while (start < currentStartIndex) {
        newPage++
        start = this.findIndexes(newPage, newPageLimit).start
      }

      this.$store.commit('company/setPageLimit', newPageLimit)
      this.$emit('page', newPage)
    }
  }
}
</script>

<style lang='scss'>
.download-results-form {
  .popper__arrow {
    left: 60% !important;
  }
}
</style>
